<template>
  <admin-fillall-layout>
    <back-button @back-click="back" />
    <div class="profile" id="device-camera-profile">
      <div class="leftPadding">
        <el-row
          class="header-row"
          type="flex"
          align="center"
          justify="space-between"
        >
          <div>
            <img
              class="thumbnail"
              src="/asserts/icon/devices-ipcam-icon.svg"
            >
          </div>
          <div class="header-row__name tooltip">
            <div class="valueLabel">
              {{ cameraData.name }}
            </div>
            <span class="tooltip-text">{{ cameraData.name }}</span>
            <hr>
          </div>
        </el-row>

        <!-- <el-row class="first-topPadding" type="flex" align="bottom" justify="space-between" >

          Relay          <el-switch v-model="nestData.relay"></el-switch>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr />
        </el-row> -->

        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.inActive') }}<div class="valueLabel">
            {{ !cameraData.inactive?$t('button.on'):$t('button.off') }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding url-tooltip"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.url') }}
          <div class="valueLabel camera-data-url">
            {{ cameraData.url }}
          </div>
          <span class="url-tooltip-text">{{ cameraData.url }}</span>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.account') }}<div class="valueLabel">
            {{ cameraData.account }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.password') }}<div class="valueLabel">
            {{ cameraData.password }}
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <el-row
          class="topPadding"
          type="flex"
          align="bottom"
          justify="space-between"
        >
          {{ $t('page.device.dialog.gpsLocation') }}
          <div class="valueLabel block__elem">
            {{ $t('page.device.dialog.latitude') }}:{{ cameraData.latitude |roundNumber(7) }}&nbsp;&nbsp;&nbsp;
            {{ $t('page.device.dialog.longitude') }}:{{ cameraData.longitude|roundNumber(7) }}
          </div>
          <div class="valueLabel block__elem--hidden">
            <div class="block__elem--item">
              {{ $t('page.device.dialog.latitude') }}:{{ cameraData.latitude | roundNumber(7) }}
            </div>
            <div class="block__elem--item">
              {{ $t('page.device.dialog.longitude') }}:{{ cameraData.longitude | roundNumber(7) }}
            </div>
          </div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr>
        </el-row>
        <!-- <el-row class="topPadding" type="flex" align="bottom" justify="space-between">
          Latitude        <div class="valueLabel">{{cameraData.latitude}}</div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr />
        </el-row>

        <el-row class="topPadding" type="flex" align="bottom" justify="space-between">
          Longitude          <div class="valueLabel">{{cameraData.longitude}}</div>
        </el-row>
        <el-row style="padding-right:35%;">
          <hr />
        </el-row> -->
      </div>
    </div>
  </admin-fillall-layout>
</template>

<script>

import droneApi from '@/services/api/domain/group/drone';
import nestApi from '@/services/api/domain/group/nest';
import { CopyObject } from '@/utils/common.js';
import BackButton from '@/components/Maps/BackButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BackButton,
  },
  computed: {
    ...mapGetters({
      groupId: 'group/getGroupId',
      droneShow: 'drone/shouldShow',
      authUser: 'auth/user',
      group: 'group/getGroup',
      cameraData: 'camera/getCamera',

    }),
  },

  data() {
    return {
      // googleMapKey: config.googleMapKey,
      deleteShow: false,
      nestDeleteShow: false,
      droneId: null,
      nestId: null,
      source: {
        items: [],
        nestItems: [],
        paging: {
          total: 0,
        },
      },
      filter: {},
      page: {
        loading: false,
      },
      drone: CopyObject(this.$store.state.drone.drone),
      nest: CopyObject(this.$store.state.nest.nest),
      //   periodUnit: this.$store.state.mission.periodUnit
    };
  },
  methods: {
    back() {
      this.$router.push({
        name: 'device',
      });
    },
    ...mapActions({
      // setDroneMode : 'drone/setMode',
      // setDrone : 'drone/setDrone',
      // setNestMode : 'nest/setMode',
      // setNest : 'nest/setNest',
    }),
  },
  mounted() {

  },
};
</script>

<style lang="scss">
#device-camera-profile{
  width: 100%;
  height: 100%;
  background-color:#f3f3f3;

  .leftPadding{
    position: relative;
    margin-left: 30%;
    top :72px;

    .header-row{
      @include profile-header-row;
      .header-row__name {
        @include profile-header-row__name;
        .valueLabel{
          @include profile-header-row-valueLabel;
          @include profile-valueLabel-ellipsis;
        }
      }
    }

    .first-topPadding{
      margin-top: 52px;
      padding-right:35%;
    }
    .topPadding{
      @include profile-topPadding;
    }
  }
  .valueLabel{
    @include profile-valueLabel;
   }

  .camera-data-url {
    @include profile-valueLabel-ellipsis;
    max-width: 80%;
  }

  .url-tooltip:hover .url-tooltip-text{
    @include tooltip-hover-style;
  }

  .url-tooltip-text {
    @include tooltip-text-style;
    top: 130%;
    right: 20%;
  }

  .tooltip {
    position: relative;
  }

  .tooltip .tooltip-text {
    @include tooltip-text-style;
    top: 43%;
    left: 66%;
  }

  .tooltip:hover .tooltip-text {
    @include tooltip-hover-style;
  }
  .block__elem {
    @include tablet-medium-860px {
      display: none;
    }
  }
  .block__elem--hidden {
    @include profile-block__elem--hidden;
  }

  .block__elem--item {
    margin-bottom: 7px;
  }
}

</style>
